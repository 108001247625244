<template>
  <Dialog dense :dialog="dialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      <v-layout>
        <v-flex>{{ hTitle }}</v-flex>
        <v-flex class="text-right">
          <v-btn :disabled="pageLoading" depressed tile v-on:click="closeDialog()"> Close </v-btn>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <v-container fluid class="pt-0">
        <v-progress-linear
          indeterminate
          height="6"
          v-if="pageLoading"
          class="position-absolute"
          color="blue darken-4"
        ></v-progress-linear>
        <v-container fluid>
          <table width="100%" class="detail-table mb-10">
            <tr width="500">
              <th width="300">Title</th>

              <td>
                <ValueTemplate v-model="history.title" title="Name (In English)"></ValueTemplate>
              </td>
            </tr>
            <tr>
              <th width="300">Description</th>
              <td>
                <span v-if="history.description" v-html="history.description"></span>
                <em v-else class="text--secondary">No Description</em>
              </td>
            </tr>
            <tr>
              <th width="300">Long Description</th>
              <td>
                <span v-if="history.long_description" v-html="history.long_description"></span>
                <em v-else class="text--secondary">No Long Description</em>
              </td>
            </tr>
            <tr>
              <th width="300">Start Date</th>
              <td>
                <ValueTemplate v-model="history.start_date" title="Start Date"></ValueTemplate>
              </td>
            </tr>
            <tr>
              <th width="300">End Date</th>
              <td>
                <ValueTemplate v-model="history.end_date" title="End Date"></ValueTemplate>
              </td>
            </tr>
            <tr>
              <th>Publish Date</th>
              <td colspan="3">
                <ValueTemplate v-model="history.publish_date" title="Publish Date"></ValueTemplate>
              </td>
            </tr>
            <tr style>
              <th class="mt-0" style="vertical-align: top">Images</th>
              <v-col>
                <v-col md="6" sm="3" class="border-light-grey">
                  <div class="d-flex">
                    <ImageTemplate
                      :src="image.url"
                      v-for="(image, ind) in history.images"
                      :key="ind"
                      class="mr-4"
                      style="width: 100px"
                      custom-class="border-light-grey"
                    ></ImageTemplate>
                  </div>
                </v-col>
              </v-col>
            </tr>

            <!-- <tr>
                    <td>
                      <v-col md="4" v-for="(image, ind) in history.images" :key="ind">
                        <ImageTemplate
                          :src="image.url"
                          :key="ind"
                          custom-class="border-light-grey"
                        ></ImageTemplate>
                      </v-col>
                    </td>
                  </tr> -->
          </table>
        </v-container>
      </v-container>
    </template>
  </Dialog>
</template>
<script>
import { FIND_PROMOTION } from "@/core/lib/promotion.lib";
//import { GET_PROFORMATION  } from "@/core/lib/customer.lib";
import { toSafeInteger } from "lodash";
//import TextInput from "@/view/components/TextInput";
// import Chip from "@/view/components/Chip";
import Dialog from "@/view/components/Dialog";
import ValueTemplate from "@/view/components/ValueTemplate";
import { SET_ERROR } from "@/core/services/store/common.module";
import ImageTemplate from "@/view/components/Image";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    hTitle: {
      type: String,
      default: null,
    },
    hId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      history: [],
      search: null,
      historyTab: "all",
      pageLoading: false,
      promotionDetails: [],
    };
  },
  watch: {
    historyTab() {
      this.findPromotion();
    },
    currentPage() {
      this.findPromotion();
    },
    listingSearch() {
      this.findPromotion();
    },
    dialog(param) {
      if (param) {
        this.findPromotion();
      }
    },
  },
  computed: {
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 80);
    },
  },
  methods: {
    closeDialog() {
      this.history = [];
      this.search = null;
      this.historyTab = "all";
      this.pageLoading = false;
      this.$emit("close", true);
    },

    // async getPromotionDetails() {
    //   try {
    //     // const form = {
    //     //   current_page: this.currentPage,
    //     //   search: this.listingSearch,
    //     // };
    //     this.pageLoading = true;
    //     const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_PROFORMATION();
    //     this.promotionDetails = rows;
    //     this.pageLimit = totalPages;
    //     this.showingFrom = showingFrom;
    //     this.showingTo = showingTo;
    //     this.totalRows = totalRows;
    //   } catch (error) {
    //     this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
    //   } finally {
    //     this.pageLoading = false;
    //   }
    // },
    async findPromotion() {
      if (this.$route.params.id) {
        try {
          this.pageLoading = true;
          const historys = await FIND_PROMOTION(this.$route.params.id, {
            search: this.search,
            tab: this.historyTab,
          });
          this.history = historys;
          console.log(this.history, "this.history");
        } catch (error) {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          this.pageLoading = false;
        }
      }
    },
  },
  mounted() {
    this.findPromotion();
    console.log(this.$route.params.id, "sfhsdgfsdf");
    // this.getPromotionDetails();
  },
  components: {
    // Chip,
    Dialog,
    //TextInput,
    ValueTemplate,
    ImageTemplate,
  },
};
</script>
